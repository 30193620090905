import React, { useContext, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import callApi from '../api/api';
import { tokenIsValid, TokenContext } from '../auth/token'
import { ErrorListContext, logErrorString } from '../error/errorList'

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'
import AlertDialog from '../components/AlertDialog'

import NotLoggedInMessage from '../components/NotLoggedInMessage'

import ReasonsDialog, { ReasonsContext } from '../components/ReasonsDialog';


function AccountPage() {

  const [accountInfo, setAccountInfo] = useState(null);
  const [cancelSubscriptionWarningDialogOpenState, setCancelSubscriptionWarningDialogOpenState] = useState(false);
  const [reasonChoice, setReasonChoice] = useState("");
  const [reasonComment, setReasonComment] = useState("");
  
  const [token, setToken] = useContext(TokenContext);
  const [errorList, setErrorList] = useContext(ErrorListContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);
  
  const navigate = useNavigate();
  
  const fetchAccountInfo = useCallback( async () => {
	const data = {
	  "action": "get_account_info",
	  "payload": { }
	};
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal)
	setAccountInfo(retVal.data)
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
  }, [token, setToken]);
  
  useEffect(() => {
    fetchAccountInfo()
  }, [fetchAccountInfo])

  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "AccountPage")) {
    return <SubscriptionExpiredMessage />;
  }
  
  if (accountInfo === null) {
    return (
      <Grid container justifyContent="center">
        <Stack spacing={2}>
          <Grid container justifyContent="center">
            <h1>Your Account</h1>
	      </Grid>
          <Grid container justifyContent="center">
	        <CircularProgress />
	      </Grid>
	    </Stack>
	  </Grid>
	);
  }
  
  // NOTE - removed for now until implement this
  //
  /*
  const editDetailsHandler = (event) => {
	console.log("editDetailsHandler");
    event.preventDefault();
	// TODO
  };
  */
  
  const changePasswordHandler = (event) => {
	console.log("changePasswordHandler");
    event.preventDefault();
	navigate("/change-password");
  };

  const selectPackageHandler = (event) => {
	console.log("selectPackageHandler");
    event.preventDefault();
	navigate("/account/package");
  };
  
  const cancelSubscriptionHandler = async (event) => {
	console.log("cancelSubscriptionHandler");
    event.preventDefault();
	
	setCancelSubscriptionWarningDialogOpenState(true);
  }
	
  const cancelSubscriptionAction = async () => {
	console.log("cancelSubscriptionAction");
	
	console.log("reasonChoice: " + reasonChoice);
	console.log("reasonComment: " + reasonComment);
	
	const data = {
	  "action": "cancel_subscription",
	  "payload": {
	    "user_cancel_reason": reasonChoice,
	    "user_cancel_comment": reasonComment,
	  }
	};
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal)
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
	// and trigger reload of account info
	fetchAccountInfo()
  };
  
  /*
  const changeSubscriptionHandler = (event) => {
	console.log("changeSubscriptionHandler");
    event.preventDefault();
	// TODO
  };
  */
  
  const makePaymentHandler = async (event) => {
	console.log("makePaymentHandler");
    event.preventDefault();

	// get payment_id for subscription
	const data = {
	  "action": "get_current_subscription_payment_id",
	  "payload": { }
	};
	
	const retVal = await callApi(token, setToken, data);
	console.log(retVal)
	logErrorString(retVal.errorMessage, errorList, setErrorList)
	
	const payment_id = retVal.data['payment_id']
	console.log("#$#$#$#$ payment_id = " + payment_id)
	if (payment_id !== null) {
	  navigate("/account/payment", { state: {paymentId: payment_id} });
	}
  };
  
  // Name
  // Email
  // Member since
  // (button to UPDATE) (button to reset PASSWORD)
  // Subscriptions
  //    Current subscription details
  //    foldable containing history of subscriptions
  // 	EITHER (button to BUY) iff subs_type === "TRIAL" or sub_end is not "Ongoing:
  //    OR     (button to CANCEL, button to CHANGE)
  // Payments
  //    next payment date and amount
  // 	foldable containing history of payments
  
  let subsButtonsJSX = ""
  if ((accountInfo.subs_type === "TRIAL") || 
       (accountInfo.subs_end !== null)) {
    if ((accountInfo.subs_type !== "TRIAL") &&
         (new Date(accountInfo.subs_end) > new Date())) {
      subsButtonsJSX = (
	    <Grid container justifyContent="center">
          <Tooltip arrow title="Please wait until current subscription ends before starting a new one.">
	        <Stack direction="row" spacing={2}>
	          <Button disabled variant="contained">New subscription</Button>
	        </Stack>
	      </Tooltip>
	    </Grid>
	  );
    } else {   
      subsButtonsJSX = (
	    <Grid container justifyContent="center">
	      <Stack direction="row" spacing={2}>
	        <Button variant="contained" onClick={selectPackageHandler}>New subscription</Button>
	      </Stack>
	    </Grid>
	  );
	}
  } else if (accountInfo.subs_type !== "PERPETUAL") {
	// NOTE removed following line until implement change subscription (for change of card or package)
	// <Button variant="outlined" onClick={changeSubscriptionHandler}>Change</Button>
	//
    subsButtonsJSX = (
	  <Grid container justifyContent="center">
	    <Stack direction="row" spacing={2}>
		  <Button variant="contained" onClick={cancelSubscriptionHandler}>Cancel</Button>
	    </Stack>
	  </Grid>
	);
  }
  
  let subsHistoryJSX = ""
  if (accountInfo.subs_history.length === 0) {
	subsHistoryJSX = (
	  <Typography>N/A</Typography>
	);
  } else {
    subsHistoryJSX = (
      <>
	    {accountInfo.subs_history.map((sub, i) => (
          <>
	        <Typography>Type : {sub.type} {i===0 ? "(CURRENT)" : ""}</Typography>
	        <Typography>Started : {sub.start_date}</Typography>
			{(sub.end_date != null) ? (
			<Typography>{new Date(sub.end) > new Date() ? "Ending" : "Ended"} : {sub.end_date} ({sub.end_reason})</Typography>
			  ) : ""}
	        <br/>
	      </>
        ))}
	  </>
    );
  }
  
  let makePaymentJSX = ""
  if ((accountInfo.payment_history.length === 0) &&
      (accountInfo.payment_amount !== "N/A"    )    ) {
    makePaymentJSX = (
	  <Grid container justifyContent="center">
	    <Stack direction="row" spacing={2}>
		  <Button variant="contained" onClick={makePaymentHandler}>Make Payment</Button>
	    </Stack>
	  </Grid>
    );
  }
  
  let paymentHistoryJSX = ""
  if (accountInfo.payment_history.length === 0) {
	paymentHistoryJSX = (
	  <Typography>N/A</Typography>
	);
  } else {
    paymentHistoryJSX = (
      <>
	    {accountInfo.payment_history.map((payment, i) => (
          <>
	        <Typography>Date   : {payment.date}</Typography>
	        <Typography>Amount : {payment.currency.toUpperCase()} {Number(payment.amount / 100).toFixed(2)}</Typography>
	        <Typography>Status : {payment.status}</Typography>
	        <br/>
	      </>
        ))}
	  </>
    );
  }
  
  let nextPaymentAmount = "N/A";
  if (accountInfo.payment_amount !== 'N/A') {
    nextPaymentAmount = accountInfo.payment_currency.toUpperCase() + " " + Number(accountInfo.payment_amount / 100).toFixed(2)
  }
  
  // NOTE - have removed following that was in parallel to the "Password" Button
  //	    (to be reinstated when can do name/email updates)
  //	<Button variant="outlined" onClick={editDetailsHandler}>Update</Button>
  //

  return (
    <ReasonsContext.Provider value={[setReasonChoice, setReasonComment]}>
    <Grid container justifyContent="center">
      <Stack spacing={2}>
        <Grid container justifyContent="center">
          <h1>Your Account</h1>
	    </Grid>
        <Grid container justifyContent="center">
	      <Card variant="outlined"  sx={{ width: 350 }}>
	        <Box m={2}>
			  <Stack spacing={2}>
				<Divider>Details</Divider>
			    <TextField
				  id={"Name"}
				  label={"Name"}
				  value={accountInfo.name}
				  InputProps={{
				    readOnly: true,
				  }}
			    />
			    <TextField
				  id={"Email"}
				  label={"Email"}
				  value={accountInfo.email}
				  InputProps={{
				    readOnly: true,
				  }}
			    />
				<TextField
				  id={"Joined"}
				  label={"Member since"}
				  value={accountInfo.date_joined}
				  InputProps={{
				    readOnly: true,
				  }}
			    />
				<Grid container justifyContent="center">
			      <Stack direction="row" spacing={2}>
				    <Button variant="contained" onClick={changePasswordHandler}>Password</Button>
			      </Stack>
				</Grid>
				
				<br/>
				<Divider>Subscriptions</Divider>
			    <TextField
				  id={"SubscriptionType"}
				  label={"Current subscription"}
				  value={accountInfo.subs_type}
				  InputProps={{
				    readOnly: true,
				  }}
			    />
			    <TextField
				  id={"SubscriptionStart"}
				  label={"Started"}
				  value={accountInfo.subs_start}
				  InputProps={{
				    readOnly: true,
				  }}
			    />
			    <TextField
				  id={"SubscriptionEnd"}
				  label={"Expires"}
				  value={accountInfo.subs_end !== null ? accountInfo.subs_end : "ongoing"}
				  InputProps={{
				    readOnly: true,
				  }}
			    />
				<Accordion>
				  <AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				  >
					<Typography>
					  Subscription history
					</Typography>
				  </AccordionSummary>
				  <AccordionDetails>
				    {subsHistoryJSX}
				  </AccordionDetails>
				</Accordion>
				{subsButtonsJSX}

				<br/>
				<Divider>Payments</Divider>
			    <TextField
				  id={"PaymentDate"}
				  label={"Next payment due"}
				  value={accountInfo.payment_date}
				  InputProps={{
				    readOnly: true,
				  }}
			    />
			    <TextField
				  id={"PaymentAmount"}
				  label={"Next payment amount"}
				  value={nextPaymentAmount}
				  InputProps={{
				    readOnly: true,
				  }}
			    />
				{makePaymentJSX}
				<Accordion>
				  <AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				  >
					<Typography>
					  Payments history
					</Typography>
				  </AccordionSummary>
				  <AccordionDetails>
				    {paymentHistoryJSX}
				  </AccordionDetails>
				</Accordion>
			  </Stack>
		    </Box>
	      </Card>
	    </Grid>
	  </Stack>
	  <AlertDialog
	    openState={cancelSubscriptionWarningDialogOpenState}
	    setOpenState={setCancelSubscriptionWarningDialogOpenState}
	    showButtons={true}
		closeButtonText="No"
		actionButtonText="Yes"
		optionalDialog={ReasonsDialog}
	    actionFunction={cancelSubscriptionAction}
	    title="Warning"
		text="You are about to cancel your subscription:"
	    buttonsDescription="Are you sure you want to cancel your subscription?"
	  />
	</Grid>
	</ReasonsContext.Provider>
  );
}

export default AccountPage;
