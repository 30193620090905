import React from 'react';

import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import linkedin_icon from '../icons/linkedin.jpg'
import instagram_icon from '../icons/instagram.jpg'
import twitter_icon from '../icons/twitter.jpg'
import facebook_icon from '../icons/facebook.jpg'
import yco_icon from '../icons/yco-logo-small.png'


function PageFooter() {
  
  const iconSize = "30";
  const iconSizeLarge = "52";
  
  return (
	<Stack spacing={0}>
	  <br/>
	  <Divider />
	  <br/>
	  <Grid container justifyContent="center">
	    <Stack direction="row" spacing={0}>
	      <Grid item justifyContent="center">
	        <Grid container justifyContent="center">
	          <Stack direction="row" spacing={1}>
		        <Link href="https://www.linkedin.com/company/your-collections-online"
				  target="_blank"
				  rel="noopener noreferrer"
				>
	              <img src={linkedin_icon} width={iconSize} height={iconSize} alt="Link to LinkedIn"/>
		        </Link>
		        <Link href="https://www.instagram.com/yourcollectionsonline?igsh=MXV6bW5uZTlkc2xsYg%3D%3D&utm_source=qr"
				  target="_blank"
				  rel="noopener noreferrer"
				>
	              <img src={instagram_icon} width={iconSize} height={iconSize} alt="Link to Instagram"/>
		        </Link>
		        <Link href="https://x.com/y_c_online"
				  target="_blank"
				  rel="noopener noreferrer"
				>
	              <img src={twitter_icon} width={iconSize} height={iconSize} alt="Link to Twitter"/>
		        </Link>
		        <Link href="https://www.facebook.com/profile.php?id=61561922759957&mibextid=LQQJ4d"
				  target="_blank"
				  rel="noopener noreferrer"
				>
	              <img src={facebook_icon} width={iconSize} height={iconSize} alt="Link to Facebook"/>
		        </Link>
	          </Stack>
	        </Grid>
	        <Grid justifyContent="center">
		      <Box>
	            <Typography sx={{ fontSize: 10, m: 1 }}>
		          &#169; Colligenda Systems Pty Ltd, 2023-2025
	            </Typography>
		      </Box>
	        </Grid>
		  </Grid>
	      <Grid item xs="auto" justifyContent="center">
		    <Link href="https://yourcollections.online/">
	          <img src={yco_icon} width={iconSizeLarge} height={iconSizeLarge} alt="Your Collections, Your Way!"/>
		    </Link>
		  </Grid>
	    </Stack>
	  </Grid>
    </Stack>
  );
}

export default PageFooter;
