import React, { useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { tokenIsValid, TokenContext } from '../auth/token'

import Constants from '../Constants'
import {
  CurrentCollectionContext,
  checkCollectableSetsValidForCollection,
  checkExampleItemsValidForCollection,
  } from '../context/currentCollection'

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { SubscriptionStatusContext, restrictPage } from '../context/subscriptionStatus'
import SubscriptionExpiredMessage from '../components/SubscriptionExpiredMessage'

import NotLoggedInMessage from '../components/NotLoggedInMessage'
import EntityDetailViewUpdate from '../components/EntityDetailViewUpdate'
import EntityList from '../components/EntityList'
import EntityTitle from '../components/EntityTitle'


function CollectableItemDetailPage(props) {

  const [token, /*setToken*/] = useContext(TokenContext);
  const [currentCollection, /*setCurrentCollection*/] = useContext(CurrentCollectionContext);
  const [subscriptionStatus, /*setSubscriptionStatus*/] = useContext(SubscriptionStatusContext);

  const navigate = useNavigate();
  
  const params = useParams();

  if (!tokenIsValid(token)) {
    return <NotLoggedInMessage />;
  }
  // nor if subscription expired and page is restricted
  //
  if (restrictPage(subscriptionStatus, "CollectableItemDetailPage")) {
    return <SubscriptionExpiredMessage />;
  }
  
  //
  //
  const createExampleItemHandler = async (event) => {
	console.log("createExampleItemHandler");
    event.preventDefault();
	
	navigate("/collections/" + params.collectionId + "/ei/" + params.collectableItemId + "/create/");
  };
 
  // set up variables for View mode
  //
  let headerText = "View";
  let primaryDisplayMode = Constants.DisplayModeView;
  //
  // or for Edit mode
  //
  if (props.editMode === true) {
	  headerText = "Update";
	  primaryDisplayMode = Constants.DisplayModeUpdate;
  }

  let collectableSetJSX = "";
  if (checkCollectableSetsValidForCollection(currentCollection, params.collectionId) &&
      (props.editMode === false)) {
    collectableSetJSX = (
	  <Grid container justifyContent="center">
		<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
		  <EntityTitle
		    title="Collectable Sets"
		    entityType={Constants.CollectableSetEntity}
		    variant="h5"
		  />
		</Stack>
	    <EntityList
		  entityType="CSforCI"
		  title=""
		  getDataAction="get_collectable_item_linked_cs_list"
	      entityId={params.collectableItemId}
		  collectionId={params.collectionId}
		  baseUrlPath={"/collections/" + params.collectionId + "/cs/"}
		  height={275}
		  enableCheckboxSelection={false}
		/>
	  </Grid>
	);
  }

  let exampleItemJSX = "";
  if (checkExampleItemsValidForCollection(currentCollection, params.collectionId) &&
      (props.editMode === false)) {
    exampleItemJSX = (
	  <Grid container justifyContent="center">
		<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
		  <EntityTitle
		    title="Example Items"
		    entityType={Constants.ExampleItemEntity}
		    variant="h5"
		  />
		  <Button variant="contained" onClick={createExampleItemHandler}>Create</Button>
		</Stack>
	    <EntityList
		  entityType="EIforCI"
		  title=""
		  getDataAction="get_collectable_item_linked_ei_list"
	      entityId={params.collectableItemId}
		  collectionId={params.collectionId}
		  baseUrlPath={"/collections/" + params.collectionId + "/ei/"}
		  height={275}
		  enableCheckboxSelection={false}
		/>
	  </Grid>
	);
  }
  
  // user is logged in so proceed to display values
  //
  // CI => EntityDetailViewUpdate </p>
  // CS => EntityList </p>
  // EI => EntityList </p>
  //
  return (
	<Stack spacing={2}>
      <Grid container justifyContent="center">
		<EntityTitle
		  title={headerText + " Collectable Item"}
		  entityType={Constants.CollectableItemEntity}
		  variant="h4"
		/>
	  </Grid>
      <Grid container justifyContent="center">
	    <EntityDetailViewUpdate
		  title="Collectable Item attributes:"
	      displayMode={primaryDisplayMode}
		  entityId={params.collectableItemId}
		  collectionId={params.collectionId}
		  getDataActionName = "get_collectable_item"
		  saveDataActionName = "update_collectable_item"
		  deleteActionName = "delete_collectable_item"
		  baseUrlPath = {"/collections/"+params.collectionId+"/ci/"}
	    />
	  </Grid>
	  {collectableSetJSX}
	  {exampleItemJSX}
	</Stack>
  );
}

export default CollectableItemDetailPage;
