import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// AlertDialog props
//
//	openState
//	setOpenState
//	showButtons
//	closeButtonText
//	actionButtonText
//	optionalDialog
//	actionFunction
//	title
//	text
//  buttonsDescription
//

function NullComponent() {
  return <></>;
};

function AlertDialog(props) {

  let closeButtonText = "Cancel";
  if (props.closeButtonText !== null) {
	closeButtonText = props.closeButtonText;
  }
  
  let actionButtonText = "Confirm";
  if (actionButtonText) {
	actionButtonText = props.actionButtonText;
  }
  
  const handleClose = () => {
    props.setOpenState(false);
  };
  
  const handleActionAndClose = () => {
	if (props.actionFunction !== null) {
	  props.actionFunction();
	}
	props.setOpenState(false);
  };

  let buttonsJSX = ""
  let buttonsDescriptionJSX = "";
  if (props.showButtons === true) {
    if (props.buttonsDescription !== null) {
	  buttonsDescriptionJSX = props.buttonsDescription;
	}
	buttonsJSX = (
	  <>
        <Button onClick={handleClose}> {closeButtonText} </Button>
        <Button onClick={handleActionAndClose} autoFocus> {actionButtonText} </Button>
	  </>
	);
  }
  
  let OptionalDialogTag = NullComponent;
  if (props.optionalDialog !== null) {
	OptionalDialogTag = props.optionalDialog;
  }
  
  return (
      <Dialog
        open={props.openState}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
		    {props.text}
          </DialogContentText>
		  <OptionalDialogTag />
		  <br/>
		  {buttonsDescriptionJSX}
        </DialogContent>
        <DialogActions>
		  {buttonsJSX}
        </DialogActions>
      </Dialog>
  );
}

export default AlertDialog;
